<template>
  <div class="book mt-4 flex">
    <div class="flex-grow">
      <div class="row-label">{{ title }}</div>
      <div v-if="chapters.length === 1" class="row-sublabel">{{ chapters.length }} chapter</div>
      <div v-else class="row-sublabel">{{ chapters.length }} chapters</div>
    </div>
    <div class>
      <div v-if="!added" @click="add" class="row-button">Add</div>
      <div v-else-if="added && !paused" @click="pause" class="row-button secondary">Pause</div>
      <div v-else-if="added && paused" @click="resume" class="row-button secondary">Resume</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    title: String,
    chapters: Array,
    added: Boolean,
    paused: Boolean
  },
  methods: {
    add() {
      this.$store.dispatch("addBook", this.id);
    },
    pause() {
      this.$store.dispatch("pauseBook", this.id);
    },
    resume() {
      this.$store.dispatch("resumeBook", this.id);
    }
  }
};
</script>