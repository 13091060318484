<template>
  <div class="home flex flex-col">
    <up-next class="px-4 py-5"></up-next>
    <!-- <recent-activity></recent-activity> -->
    <!-- <next-chapters class="flex-grow" /> -->
  </div>
</template>

<script>
import UpNext from "@/components/home/UpNext";
import NextChapters from "@/components/home/NextChapters";
import RecentActivity from "@/components/home/RecentActivity";

export default {
  components: {
    UpNext,
    NextChapters,
    RecentActivity,
  },
};
</script>

<style lang="scss" scoped>
// .up-next {
//   padding-top: 100px;
//   min-height: 60vh;
// }
</style>