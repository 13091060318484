<!--

  Try to fix the truncated text with this:
  https://css-tricks.com/flexbox-truncated-text/

-->
<template>
  <div class="review-panel safe-area">
    <div class="header flex items-center">
      <!-- Uncomment to implement book_logo -->
      <book-logo v-if="book_logo" class="mr-3 flex-none" :logoUrl="book_logo" />
      <div class="flex-grow">
        <div class="font-bold text-sm">
          {{ book_title }}
        </div>
        <div class="text-sm">{{ chapter_title }}</div>
      </div>
      <close-button class="flex-none ml-3" @click="close" />
    </div>
    <flashcards-group class="flashcards" mode="review" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FlashcardsGroup from "@/components/flashcards/FlashcardsGroup.vue";
import CloseButton from "@/components/CloseButton.vue";
import BookLogo from "@/components/BookLogo.vue";

// Capacitor
import { Plugins, StatusBarStyle } from "@capacitor/core";
const { StatusBar } = Plugins;

export default {
  components: { FlashcardsGroup, CloseButton, BookLogo },
  computed: {
    ...mapGetters(["currentReviewFlashcard"]),
    book_logo() {
      // https://www.figma.com/file/GYJEAJQtYY4OoY1r6HDG5I/Book-Logos
      return this.$store.getters.bookLogoFromFlashcard(
        this.currentReviewFlashcard
      );
    },
    book_title() {
      return this.$store.getters.bookTitleFromFlashcard(
        this.currentReviewFlashcard
      );
    },
    chapter_title() {
      return this.$store.getters.chapterTitleFromFlashcard(
        this.currentReviewFlashcard
      );
    },
  },
  methods: {
    close() {
      this.$store.dispatch("closeOverlay");
    },
  },
  mounted() {
    if (this.$isNative) StatusBar.hide();
  },
  beforeDestroy() {
    if (this.$isNative) StatusBar.show();
  },
};
</script>

<style lang="scss" scoped>
.review-panel {
  background: white;
  display: flex;
  flex-direction: column;
}

.header {
  @apply border-b;
  @apply px-4;
  @apply py-3;
}

.flashcards {
  flex-grow: 1;
}
</style>