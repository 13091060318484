<template>
  <div
    class="text-center info mt-4 px-2"
  >We take a look at the idea of agents, a central concept in reinforcement learning.</div>
</template>

<script>
export default {};
</script>

<style>
</style>