<template>
  <div class="bg-dark text-white px-4 py-5">
    <div class="caps text-yellow">Coming Next</div>
    <div class="info mt-4">A first look at agents</div>
    <div
      class="info-small mt-2 mb-3 text-lightest"
    >We take a look at the idea of agents, a central concept in reinforcement learning.</div>
    <div class="info mt-4">The return</div>
    <div class="info mt-4">The guys in the pit</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([])
  }
};
</script>

<style lang="scss" scoped>
</style>