<template>
  <div class="login p-4">
    <div>
      <router-link to="/login" class="back inline-block ml-neg-4">
        <primer-glyph name="back-24" />
      </router-link>
      <template v-if="!resetSent">
        <div class="headline">Reset password</div>
        <div
          class="info-small mt-4 mb-4"
        >Enter your email address and we’ll send you a reset email straight away.</div>
        <form v-on:submit="reset">
          <div class="form-val-error" v-if="showErrors">Could not reset password.</div>
          <input type="email" name="email" placeholder="Email address" v-model="email" />
          <input
            type="submit"
            class="w-full mt-4"
            :disabled="hasLocalErrors"
            value="Reset password"
          />
        </form>
      </template>
      <template v-else>
        <div class="headline">Reset email sent</div>
        <div
          class="info-small mt-4 mb-4"
        >Please check your email for password reset instructions. It might take a couple of minutes to arrive and might end up in your spam folder.</div>
      </template>
    </div>
  </div>
</template>

<script>
const R = require("ramda");
import PrimerGlyph from "@/components/PrimerGlyph.vue";

export default {
  data() {
    return {
      email: "",
      showErrors: false,
      resetSent: false,
      waiting: false
    };
  },
  components: { PrimerGlyph },
  computed: {
    hasLocalErrors() {
      return this.emailInvalid || this.waiting;
    },
    emailInvalid() {
      if (R.isEmpty(this.email)) return true;
      return false;
    }
  },
  methods: {
    reset(e) {
      this.waiting = true;
      e.preventDefault();
      let email = this.email;
      let data = {
        email
      };
      this.$http
        .post("/auth/forgotpassword", data)
        .then(response => {
          this.resetSent = true;
        })
        .catch(err => {
          this.showErrors = true;
          this.waiting = false;
        });
    }
  }
};
</script>
