const R = require("ramda");
import api from "@/store/api";

const state = {
  dueFlashcards: [],
  dueFlashcardsLoaded: false,
  flashcardStatuses: [],
  currentFlashcardIndex: 0,
  currentReviewFlashcardIndex: 0,
};

const getters = {
  dueFlashcardsLoaded(state) {
    return state.dueFlashcardsLoaded;
  },
  currentFlashcardIndex(state) {
    return state.currentFlashcardIndex;
  },
  currentFlashcard(state, getters) {
    if (!getters.currentNote) return;
    return getters.currentNote.flashcards[getters.currentFlashcardIndex];
  },
  currentReviewFlashcard(state, getters) {
    if (!getters.dueFlashcards) return;
    return getters.dueFlashcards[getters.currentReviewFlashcardIndex];
  },
  currentReviewFlashcardIndex(state, getters) {
    return state.currentReviewFlashcardIndex;
  },
  isLastReviewFlashcard(state, getters) {
    return (
      getters.currentReviewFlashcardIndex == getters.dueFlashcards.length - 1
    );
  },
  flashcardStatuses(state, getters) {
    return state.flashcardStatuses;
  },
  flashcardIsCollected: (state, getters) => (id) => {
    const statuses = state.flashcardStatuses;
    const match = R.find(R.propEq("flashcard_id", id), statuses);
    return Boolean(match);
  },
  dueFlashcards(state, getters) {
    return state.dueFlashcards;
  },
  bookLogoFromFlashcard: (state, getters) => (flashcard) => {
    if (flashcard.book_logo_url) return flashcard.book_logo_url;
    return "";
  },
  bookTitleFromFlashcard: (state, getters) => (flashcard) => {
    if (flashcard.book_title) return flashcard.book_title;
    else if (flashcard.user_flashcards_book_title)
      return flashcard.user_flashcards_book_title;
    return "";
  },
  chapterTitleFromFlashcard: (state, getters) => (flashcard) => {
    if (flashcard.chapter_title) return flashcard.chapter_title;
    else if (flashcard.user_flashcards_chapter_title)
      return flashcard.user_flashcards_chapter_title;
    return "";
  },
};

const mutations = {
  setFlashcardStatuses(state, statuses) {
    state.flashcardStatuses = statuses;
  },
  setCurrentFlashcardIndex(state, index) {
    state.currentFlashcardIndex = index;
  },
  setCurrentReviewFlashcardIndex(state, index) {
    state.currentReviewFlashcardIndex = index;
  },
  setFlashcardCollected(state, { flashcard_id }) {
    state.flashcardStatuses = R.append(
      { flashcard_id },
      state.flashcardStatuses
    );
  },
  setDueFlashcards(state, flashcards) {
    state.dueFlashcards = flashcards;
  },
  setDueFlashcardsLoaded(state, value) {
    state.dueFlashcardsLoaded = value;
  },
};

const actions = {
  async loadDueFlashcards({ commit, getters, dispatch }) {
    commit("setDueFlashcardsLoaded", false);
    const flashcardsRes = await api.get("user/flashcards/due");
    commit("setDueFlashcards", flashcardsRes.data);
    commit("setDueFlashcardsLoaded", true);
  },
  async loadFlashcardStatuses({ commit, getters, dispatch }) {
    if (!getters.nextChapter) return;
    const chapter_id = getters.nextChapter.chapter_id;
    const flashcards = await api.get(`user/flashcards/chapter/${chapter_id}`);
    commit("setFlashcardStatuses", flashcards.data);
  },
  collectFlashcard({ commit, getters, dispatch }, { id }) {
    api
      .post(`user/flashcards/${id}/collect`)
      .then(() => {
        commit("setFlashcardCollected", { flashcard_id: id });
      })
      .catch((err) => {
        console.log("Could not collect flashcard.");
      });
  },
  nextFlashcard({ commit, getters, dispatch }) {
    if (
      getters.currentFlashcardIndex ==
      getters.currentNote.flashcards.length - 1
    ) {
      commit("setCurrentFlashcardIndex", 0);
      dispatch("nextNote");
    } else {
      commit("setCurrentFlashcardIndex", getters.currentFlashcardIndex + 1);
    }
  },
  nextReviewFlashcard({ commit, getters, dispatch }) {
    if (getters.isLastReviewFlashcard) dispatch("closeOverlay");
    else {
      commit(
        "setCurrentReviewFlashcardIndex",
        getters.currentReviewFlashcardIndex + 1
      );
    }
  },
  answerFlashcard({ commit, getters, dispatch }, { id, result, moveToNext }) {
    console.log(`Answering flashcard ${id} as ${result}`);
    let last = getters.isLastReviewFlashcard;
    api
      .put(`user/flashcards/${id}/review`, { rating: result })
      .then(() => {
        console.log("Flashcard rated");
        if (last) dispatch("preloadContent");
      })
      .catch((err) => {
        console.log("Could not rate flashcard");
      });
    if (moveToNext) dispatch("nextReviewFlashcard");
  },
};

export default { state, getters, mutations, actions };
