<template>
  <div class="login p-4">
    <div>
      <router-link :to="{ name: 'welcome' }" class="back inline-block ml-neg-4">
        <primer-glyph name="back-24" />
      </router-link>
      <div class="headline mb-5">Log in</div>
      <form v-on:submit="login">
        <div class="form-val-error" v-if="showErrors">Could not log you in.</div>
        <input type="email" name="email" placeholder="Email address" v-model="username" />
        <input type="password" name="password" placeholder="Password" v-model="password" />
        <input
          type="submit"
          class="w-full mt-4"
          :disabled="hasLocalErrors || waiting"
          value="Log In"
        />
      </form>
      <div class="info-small mt-4 mb-4">
        <router-link to="/forgotpassword" class="link">Forgot password?</router-link>
      </div>
    </div>
  </div>
</template>

<script>
const R = require("ramda");
import PrimerGlyph from "@/components/PrimerGlyph.vue";

export default {
  data() {
    return {
      username: "",
      password: "",
      unauthorized: false,
      showErrors: false,
      waiting: false
    };
  },
  components: { PrimerGlyph },
  computed: {
    hasLocalErrors() {
      return this.emailInvalid || this.passwordInvalid;
    },
    emailInvalid() {
      if (R.isEmpty(this.username)) return true;
      return false;
    },
    passwordInvalid() {
      if (R.isEmpty(this.password)) return true;
      if (this.password.length < 8) return true;
      return false;
    }
  },
  methods: {
    login(e) {
      e.preventDefault();
      this.waiting = true;
      let email = this.username;
      let password = this.password;
      let data = {
        username: email,
        password: password
      };
      this.$http
        .post("/auth/login", data)
        .then(response => {
          this.$store.dispatch("setLocalStorage", response.data);
          this.$router.push({ name: "home" });
        })
        .catch(err => {
          if (err.response.status == 401) this.unauthorized = true;
          this.showErrors = true;
          this.waiting = false;
        });
    }
  }
};
</script>
