<template>
  <div class="p-4 m-4 border">
    <div>
      upcomingChaptersRequested
      <span class="font-bold">{{ upcomingChaptersRequested }}</span>
    </div>
    <div>
      upcomingChaptersRequested
      <span class="font-bold">{{ upcomingChaptersRequested }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["upcomingChaptersRequested"])
  }
};
</script>

<style>
</style>