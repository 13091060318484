<template>
  <div class="login p-4">
    <div>
      <router-link to="/login" class="back inline-block ml-neg-4">
        <primer-glyph name="back-24" />
      </router-link>
      <template v-if="!checkedToken">
        <div class="headline text-light">Loading...</div>
      </template>
      <template v-else-if="!token |!validToken">
        <div class="headline">Link expired</div>
        <div class="info-small mt-4 mb-4">
          Trying to reset your password? The link you clicked on may have expired. You can
          <router-link to="/forgotpassword" class="link">request a new link</router-link>.
        </div>
      </template>
      <template v-else-if="!resetCompleted">
        <div class="headline">Reset password</div>
        <div class="info-small mt-4 mb-4">Enter a new password.</div>
        <form v-on:submit="reset">
          <div class="form-val-error" v-if="showErrors">Could not reset password.</div>
          <input
            type="password"
            name="password"
            placeholder="Password (8+ characters)"
            v-model="password"
          />
          <input
            type="submit"
            class="w-full mt-4"
            :disabled="hasLocalErrors"
            value="Reset password"
          />
        </form>
      </template>
      <template v-else-if="resetCompleted">
        <div class="headline">Password changed</div>
        <div class="info-small mt-4 mb-4">
          All done! Your password has been changed and you are logged in.
          <router-link :to="{ name: 'home' }" class="button block mt-4">Go to homepage</router-link>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
const R = require("ramda");
import PrimerGlyph from "@/components/PrimerGlyph.vue";

export default {
  data() {
    return {
      checkedToken: false,
      validToken: false,
      password: "",
      waiting: false,
      showErrors: false,
      resetCompleted: false
    };
  },
  components: { PrimerGlyph },
  computed: {
    hasLocalErrors() {
      return !this.password || this.password.length < 8;
    },
    token() {
      return this.$route.query.token;
    }
  },
  methods: {
    async checkToken(token) {
      if (!token) {
        this.checkedToken = true;
        this.validToken = false;
        return;
      }

      this.$http
        .post("/auth/checktoken", { token })
        .then(response => {
          this.checkedToken = true;
          this.validToken = true;
        })
        .catch(err => {
          this.checkedToken = true;
          this.validToken = false;
        });
    },
    reset(e) {
      if (this.hasLocalErrors) return;
      this.waiting = true;
      e.preventDefault();
      let password = this.password;
      let token = this.token;
      let data = { password, token };
      this.$http
        .post("/auth/resetpassword", data)
        .then(response => {
          this.resetCompleted = true;
        })
        .catch(err => {
          this.showErrors = true;
          this.waiting = false;
        });
    }
  },
  async mounted() {
    this.checkToken(this.token);
  }
};
</script>
