// AUTOGENERATED FILE
// Edit the version in /shared to update this

const R = require("ramda");

const bodyProp = "body";
const bodyLens = R.lensProp(bodyProp);
const addEndFlourish = (note) => {
  note.note_body = note.note_body + "&nbsp;&nbsp;◆";
  return note;
};

// array of notes -> array of notes split into builds
const splitBuilds = function (notes) {
  // only do it if you have notes
  if (!notes) return;

  // utils
  const split = R.pipe(R.trim, R.split("\n\n"));

  const mergeWithPrev = (arr) => {
    let mergedArr = [];

    while (arr.length) {
      const concat = R.join("\n\n", arr);
      mergedArr = R.prepend(concat, mergedArr);
      arr = R.dropLast(1, arr);
    }

    return mergedArr;
  };

  const expandNote = (note) => {
    const body = R.defaultTo("", R.view(bodyLens, note));
    const expandedBody = split(body);
    const expandedIncludePrev = mergeWithPrev(expandedBody);
    const addBodyToObj = R.assoc(bodyProp, R.__, note);
    const output = R.map(addBodyToObj, expandedIncludePrev);
    const head = R.init(output);
    const tail = R.last(output);
    if (R.isEmpty(head)) return output;
    else {
      const marked = R.map(R.assoc("isIncremental", true), head);
      const markedOutput = R.append(tail, marked);
      return markedOutput;
    }
  };

  return R.flatten(R.map(expandNote, notes));
};

module.exports = { addEndFlourish, splitBuilds };
