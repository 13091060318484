<template>
  <div
    class="button"
    :class="{ 'button-full' : wide, 'disabled': disabled }"
    @click="$emit('click')"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    wide: Boolean,
    disabled: Boolean
  }
};
</script>