<template>
  <div>
    <!-- Dashboard -->
    <div class="home flex flex-col max-h-screen">
      <app-nav />
      <router-view class="flex-grow overflow-scroll" />
      <!-- <debug-items /> -->
    </div>

    <!-- Shield -->
    <div v-if="overlayOpen" class="shield" @click="$store.dispatch('closeOverlay')"></div>

    <!-- Learning -->
    <transition name="slide-up">
      <learning-panel v-if="learningOpen" />
    </transition>
    <transition name="slide-up">
      <review-panel v-if="reviewOpen" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppNav from "@/components/AppNav/AppNav.vue";
import LearningPanel from "@/views/learning/LearningPanel.vue";
import ReviewPanel from "@/views/review/ReviewPanel.vue";
import DebugItems from "@/components/debug/DebugItems.vue";

export default {
  components: { AppNav, LearningPanel, ReviewPanel, DebugItems },
  computed: {
    ...mapGetters(["overlayOpen", "learningOpen", "reviewOpen"])
  }
};
</script>

<style lang="scss" scoped>
.home {
  transition: 0.3s all ease-in-out;
  background: white;
}

.shield {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

$padding: 0;

.learning-panel,
.review-panel {
  position: absolute;
  left: $padding;
  right: $padding;
  bottom: $padding;
  top: $padding;
  border-radius: 0;
}
</style>