import Vue from "vue";
import Vuex from "vuex";

import api from "@/store/api";

// Modules
import user from "@/store/modules/user.js";
import books from "@/store/modules/books.js";
import chapter from "@/store/modules/chapter.js";
import library from "@/store/modules/library.js";
import flashcards from "@/store/modules/flashcards.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { user, books, chapter, library, flashcards },
  state: {
    learningOpen: false,
    reviewOpen: false,
  },
  getters: {
    overlayOpen(state, getters) {
      return getters.learningOpen || getters.reviewOpen;
    },
    learningOpen(state) {
      return state.learningOpen;
    },
    reviewOpen(state) {
      return state.reviewOpen;
    },
  },
  mutations: {
    setLearningOpen(state, payload) {
      state.learningOpen = payload;
    },
    setReviewOpen(state, payload) {
      state.reviewOpen = payload;
    },
  },
  actions: {
    preloadContent({ dispatch }) {
      dispatch("loadNextChapter");
      dispatch("loadDueFlashcards");
      dispatch("loadRecentChapters");
      dispatch("loadLibrary");
    },
    closeOverlay({ commit, dispatch }) {
      dispatch("preloadContent");
      commit("setLearningOpen", false);
      commit("setReviewOpen", false);
    },
    openLearning({ commit, state }) {
      commit("setCurrentNoteIndex", 0);
      commit("setCurrentFlashcardIndex", 0);
      commit("setLearningOpen", true);
    },
    openLearningAtChapter({ commit, dispatch }, { b_id, c_id }) {
      dispatch("loadSpecificChapter", { b_id, c_id });
      dispatch("openLearning");
    },
    openReview({ commit, state }) {
      commit("setCurrentReviewFlashcardIndex", 0);
      commit("setCurrentFlashcardIndex", 0);
      commit("setReviewOpen", true);
    },
    toggleLearningOpen({ commit, state }) {
      commit("setLearningOpen", !state.learningOpen);
    },
    toggleReviewOpen({ commit, state }) {
      commit("setReviewOpen", !state.reviewOpen);
    },
  },
});
