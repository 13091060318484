const R = require("ramda");
import api from "@/store/api";

const libraryIsFresh = (lastLoadedTime) => Date.now() - lastLoadedTime < 10000;

const state = {
  library: [],
  yourLibrary: [],
  libraryLoaded: false,
  lastLoadedTime: 0,
};

const getters = {
  libraryLoaded(state) {
    return state.libraryLoaded;
  },
  library(state) {
    return state.library;
  },
  libraryWithoutAdded(state, getters) {
    const all = getters.library;
    const yours = state.yourLibrary;
    const yoursIds = R.pluck("book_id", yours);
    const without = R.reject(
      R.propSatisfies(R.includes(R.__, yoursIds), "book_id"),
      all
    );
    return without;
  },
  yourLibrary(state, getters) {
    return R.filter(R.propEq("active", true), state.yourLibrary);
  },
  pausedLibrary(state, getters) {
    if (!state.yourLibrary.length) return [];
    return R.filter(R.propEq("active", false), state.yourLibrary);
  },
  libraryLastLoadedTime(state) {
    return state.lastLoadedTime;
  },
};

const actions = {
  async loadLibrary({ commit, dispatch, getters }) {
    try {
      const allBooks = await api.get("user/books");
      const yourBooks = await api.get("user/books/reading");
      commit("setLibrary", allBooks.data);
      commit("setYourLibrary", yourBooks.data);
      state.libraryLoaded = true;
      state.lastLoadedTime = Date.now();
    } catch (err) {
      console.log(err);
    }
  },
};

const mutations = {
  setLibrary(state, books) {
    state.library = books;
  },
  setYourLibrary(state, books) {
    state.yourLibrary = books;
  },
};

export default { state, getters, mutations, actions };
