<template>
  <div class="select-none px-4 py-5">
    <div class="text-center">
      <div class="big-copy mb-2">📶</div>
      <div class="small-title">No internet connection</div>
      <router-link tag="div" class="button button-secondary mt-4" :to="{ name: 'home' }">Retry</router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
