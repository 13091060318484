// api.js
// -------
// This file gets imported anywhere something needs to access the Primer API.
//
// This means:
// 1) We instantiate the HTTP lib once, meaning that cookies etc are shared
// 2) We can swap out the HTTP lib if needed

import axios from "axios";

const isProduction = process.env.NODE_ENV === "production";

const baseURL = "https://api.primerlearn.com/v1/";

const api = axios.create({
  withCredentials: true,
  baseURL: isProduction ? baseURL : "http://localhost:3000/v1/",
});

export default api;
