const R = require("ramda");
import api from "@/store/api";

const state = {};

const getters = {};

const actions = {
  addBook({ commit, dispatch, getters }, book_id) {
    api
      .post(`user/books/${book_id}/subscribe`)
      .then((res) => dispatch("loadLibrary"))
      .catch((err) => {
        console.log("An error occured", err);
      });
  },
  pauseBook({ commit, dispatch, getters }, book_id) {
    api
      .post(`user/books/${book_id}/pause`)
      .then((res) => dispatch("loadLibrary"))
      .catch((err) => {
        console.log("An error occured", err);
      });
  },
  resumeBook({ commit, dispatch, getters }, book_id) {
    api
      .post(`user/books/${book_id}/resume`)
      .then((res) => dispatch("loadLibrary"))
      .catch((err) => {
        console.log("An error occured", err);
      });
  },
};

const mutations = {};

export default { state, getters, mutations, actions };
