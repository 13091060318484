<template>
  <div class="signup p-4">
    <div>
      <router-link :to="{ name: 'welcome' }" class="back inline-block ml-neg-4">
        <primer-glyph name="back-24" />
      </router-link>
      <div class="headline mb-5">Create an account</div>
      <form v-on:submit="signup">
        <div class="form-val-error" v-if="showErrors">
          <div v-if="serverEmailError">Double check your email address?</div>
          <div v-if="serverInvalidBetacode">That beta code is not valid</div>
        </div>
        <input type="email" name="email" placeholder="Email address" v-model="email" />
        <input
          type="password"
          name="password"
          placeholder="Password (8+ characters)"
          v-model="password"
        />
        <input type="text" name="betacode" placeholder="Beta code" v-model="betacode" />
        <input type="submit" class="w-full mt-4" :disabled="hasLocalErrors" value="Get Started" />
      </form>
      <div class="info-small mt-4 mb-4">
        Already have an account?
        <router-link to="/login" class="link">Login</router-link>
      </div>
    </div>
  </div>
</template>

<script>
const R = require("ramda");
import PrimerGlyph from "@/components/PrimerGlyph.vue";

const emailTaken = R.propEq("error", "Key already exists");
const invalidPassword = R.propEq("param", "password");

export default {
  data() {
    return {
      email: "",
      password: "",
      betacode: "",
      serverEmailError: true,
      serverPasswordInvalid: false,
      serverInvalidBetacode: false,
      showErrors: false,
    };
  },
  components: { PrimerGlyph },
  computed: {
    hasLocalErrors() {
      return this.emailInvalid || this.passwordInvalid || this.betacodeInvalid;
    },
    emailInvalid() {
      if (R.isEmpty(this.email)) return true;
      return false;
    },
    betacodeInvalid() {
      if (R.isEmpty(this.betacode)) return true;
      return false;
    },
    passwordInvalid() {
      if (R.isEmpty(this.password)) return true;
      if (this.password.length < 8) return true;
      return false;
    },
  },
  methods: {
    resetErrors() {
      this.serverEmailError = false;
      this.serverPasswordInvalid = false;
      this.serverInvalidBetacode = false;
      this.showErrors = false;
    },
    signup(e) {
      if (this.hasLocalErrors) return;
      this.resetErrors();
      e.preventDefault();
      let email = this.email;
      let password = this.password;
      let betacode = this.betacode;
      let data = {
        email: email,
        password: password,
        betacode: betacode,
      };
      this.$http
        .post("/auth/signup", data)
        .then((response) => {
          this.$router.push({ name: "home" });
        })
        .catch((error) => {
          const response = error.response.data.errors;
          if (response) {
            if (response.error == "Key already exists")
              this.serverEmailError = true;
            else if (
              response.msg == "Invalid value" &&
              response.param == "email"
            )
              this.serverEmailError = true;
            if (R.any(invalidPassword, response))
              this.serverPasswordInvalid = true;
          } else {
            if (error.response.data.msg == "Invalid betacode")
              this.serverInvalidBetacode = true;
          }
          this.showErrors = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
