<template>
  <div class="welcome p-4 select-none">
    <div class="mt-2 mb-5">
      <!-- <primer-glyph name="logo-small" /> -->
    </div>
    <div class="headline w-8/12 mb-4">A better way to learn.</div>
    <div class="info mb-5"></div>
    <router-link to="/signup" class="button block mb-4">Sign up</router-link>
    <div>
      Already have an account?
      <router-link v-if="!$isNative" to="/login" class="link">Login</router-link>
      <template v-else>
        <span @click="openNativeLogin" class="link">Login</span>
      </template>
    </div>
  </div>
</template>

<script>
import { Plugins } from "@capacitor/core";
const { PrimerPlugin } = Plugins;

import PrimerGlyph from "@/components/PrimerGlyph.vue";

export default {
  data() {
    return {
      showPage: false,
    };
  },
  methods: {
    openNativeLogin() {
      PrimerPlugin.openLogin();
    },
    redirectHome() {
      this.$router.push({ name: "home" });
    },
  },
  created() {
    window.addEventListener("loginSuccess", this.redirectHome);
  },
  destroyed() {
    window.removeEventListener("loginSuccess", this.redirectHome);
  },
  components: { PrimerGlyph },
};
</script>

<style lang="scss" scoped>
</style>
