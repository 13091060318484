<template>
  <div class="card flex flex-col" :class="{ collect : mode === 'collect' }" @click="flipOrContinue">
    <div class="copy flex-grow">
      <div class="medium-copy side" v-html="front"></div>
      <div class="tap-hint caps" v-show="!showBack">Tap to show answer</div>
      <div ref="back" class="medium-copy side side-back border-t" v-if="showBack" v-html="back"></div>
    </div>
    <div class="responses">
      <div class="p-4 w-full" v-if="showBack">
        <div v-if="mode === 'collect'">
          <div v-if="!collected" @click.stop="collect" class="button save-button">Save to deck</div>
          <div v-else class="saved">✓ Saved</div>
        </div>
        <div v-else class="responses mt-4">
          <div @click.stop="review(0)" class="button button-secondary">Forgot</div>
          <div @click.stop="review(2)" class="button">Correct</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { card: Object, mode: { type: String, default: "collect" } },
  data() {
    return { showBack: false };
  },
  computed: {
    collected() {
      return this.$store.getters.flashcardIsCollected(this.card.id);
    },
    front() {
      return this.$md.makeHtml(this.card.front);
    },
    back() {
      return this.$md.makeHtml(this.card.back);
    }
  },
  methods: {
    flip() {
      if (!this.showBack) {
        this.showBack = true;
        this.$nextTick(() => {
          this.scrollToBack();
        });
      }
    },
    flipOrContinue() {
      if (this.mode === "collect" && this.showBack && this.collected)
        this.nextFlashcard();
      else this.flip();
    },
    scrollToBack() {
      this.$refs.back.scrollIntoView();
    },
    collect() {
      this.$store.dispatch("collectFlashcard", { id: this.card.id });
      this.nextFlashcard();
    },
    review(result) {
      this.$store.dispatch("answerFlashcard", {
        id: this.card.id,
        result,
        moveToNext: true
      });
    },
    nextFlashcard() {
      this.$store.dispatch("nextFlashcard");
    },
    reset() {
      window.scrollTo(0, 0);
      this.showBack = false;
    }
  },
  watch: {
    card(to, from) {
      this.reset();
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  flex-grow: 1;
  @apply text-dark;
}

.side {
  @apply p-4;
}

.tap-hint {
  @apply text-dark;
  @apply p-4;
}

.collect {
  .card {
    color: white;
  }
  .side-back {
    border-color: white;
  }
}

.responses {
  display: flex;
  .button {
    width: 50%;
    height: 48px;
    border: 0px solid;
    border-radius: 0 8px 8px 0;
  }
  .button-secondary {
    background: #aaa;
    color: white;
    border-radius: 8px 0 0 8px;
  }
}

.responses .save-button {
  border-radius: 8px;
  width: 100%;
}

.saved {
}
</style>