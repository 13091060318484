<template>
  <div class="flashcards-group overflow-scroll">
    <flashcard :card="nextCard" :mode="mode" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Flashcard from "@/components/flashcards/Flashcard.vue";

export default {
  props: { mode: { type: String, default: "collect" } },
  components: { Flashcard },
  computed: {
    ...mapGetters(["currentFlashcard", "currentReviewFlashcard"]),
    nextCard() {
      if (this.mode === "collect") return this.currentFlashcard;
      else return this.currentReviewFlashcard;
    }
  }
};
</script>

<style lang="scss" scoped>
.flashcards-group {
  display: flex;
  flex-direction: column;
}
</style>