<template>
  <div class="not-found p-4">
    <div class="text-lg mb-4">Sorry, we couldn't find that page on Primer.</div>
    <div class="text-blue">
      <router-link :to="{ name: 'home' }">&larr; Return home</router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
