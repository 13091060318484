import Vue from "vue";
import VueRouter from "vue-router";

import Welcome from "../views/Welcome.vue";
import MainApp from "../views/MainApp.vue";
import Home from "../views/Home.vue";
import SignUp from "../views/SignUp.vue";
import Login from "../views/Login.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Logout from "../views/Logout.vue";
import Library from "../views/Library.vue";
// errors
import NotFound from "../views/NotFound.vue";
import NetworkError from "../views/NetworkError.vue";

Vue.use(VueRouter);
import store from "@/store";
import api from "@/store/api";

const routes = [
  {
    path: "/welcome",
    name: "welcome",
    component: Welcome,
    meta: { loggedOutOnly: true },
  },
  {
    path: "/",
    component: MainApp,
    meta: { requiresAuth: true },
    children: [
      { path: "", name: "home", component: Home },
      { path: "library", name: "library", component: Library },
    ],
  },
  {
    path: "/signup",
    name: "signup",
    meta: { loggedOutOnly: true },
    component: SignUp,
  },
  {
    path: "/login",
    name: "login",
    meta: { loggedOutOnly: true },
    component: Login,
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    meta: { loggedOutOnly: true },
    component: ForgotPassword,
  },
  {
    path: "/resetpassword",
    name: "resetpassword",
    component: ResetPassword,
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
  },
  {
    path: "/network-error",
    name: "networkerror",
    component: NetworkError,
  },
  {
    // 404
    path: "*",
    name: "404",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    else return { x: 0, y: 0 };
  },
});

const requiresAuth = (route) =>
  route.matched.some((record) => record.meta.requiresAuth);

const loggedOutOnly = (route) =>
  route.matched.some((record) => record.meta.loggedOutOnly);

router.beforeEach(async (to, from, next) => {
  // These routes are only accessible when logged out. To not delay actually
  // logged out users, we load the page before checking the user's status, and
  // then redirect if they turn out to be logged in.
  if (loggedOutOnly(to)) {
    next();
    try {
      await api.get("/auth/ping");
      store.dispatch("setAuthenticated");
      next({ name: "home" }); // redirect
    } catch (err) {
      if (err.response) {
        // not logged in
        store.dispatch("setLoggedOut");
      } else {
        // network error
        next({ name: "networkerror" }); // redirect
      }
    }
  }
  // These routes don't need the user to be logged in.
  else if (!requiresAuth(to)) next();
  // These routes need the user to be logged in.
  // Firstly, if they are already
  else if (store.getters.isAuthenticated) next();
  // Then, if they are not, check first
  else {
    api
      .get("/auth/ping")
      .then(() => {
        store.dispatch("setAuthenticated");
        next();
      })
      .catch(() => {
        store.dispatch("setLoggedOut");
        next({ name: "welcome" });
      });
  }
});

export default router;
