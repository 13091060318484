<template>
  <div class="invisible-nav absolute top-0 left-0 bottom-0 right-0" :class="{ 'opacity-0' : !tip }">
    <div class="prev absolute top-0 left-0 bottom-0 w-1/3" @click="prev">
      <div class="tip absolute left-0 bottom-0 right-0 align-right pr-3 pb-5">&larr; Back</div>
    </div>
    <div class="next absolute top-0 right-0 bottom-0 w-2/3" @click="next">
      <div class="tip absolute left-0 bottom-0 right-0 pl-3 pb-5">Forward &rarr;</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tip: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    prev() {
      this.$emit("prev");
    },
    next() {
      this.$emit("next");
    }
  }
};
</script>

<style scoped>
.invisible-nav {
  transition: opacity 0.2s ease-in-out;
  user-select: none;
}
.prev {
  /* background: rgba(255, 255, 0, 0.2); */
  text-align: right;
}
.next {
  /* background: rgba(0, 255, 0, 0.2); */
}
.tip {
}
</style>