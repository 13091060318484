<template>
  <div class="p-4">
    <div class="info">
      <template v-if="!loaded">Loading...</template>
      <template v-else>
        <div v-if="yourLibrary.length" class="mb-5">
          <div class="caps text-yellow">Your books</div>
          <book-row
            v-for="book in yourLibrary"
            :id="book.book_id"
            :title="book.book_title"
            :chapters="book.chapters"
            :added="true"
            :paused="!book.active"
            :key="book.id"
            class="mb-2"
          />
        </div>
        <div v-if="pausedLibrary.length" class="mb-5">
          <div class="caps text-yellow">Paused books</div>
          <book-row
            v-for="book in pausedLibrary"
            :id="book.book_id"
            :title="book.book_title"
            :chapters="book.chapters"
            :added="true"
            :paused="!book.active"
            :key="book.id"
            class="mb-2"
          />
        </div>
        <div v-if="libraryWithoutAdded.length" class="caps text-yellow">All books</div>
        <book-row
          v-for="book in libraryWithoutAdded"
          :id="book.book_id"
          :title="book.book_title"
          :chapters="book.chapters"
          :key="book.id"
          class="mb-2"
        />
        <div class="border-t mt-4 pt-4">
          <div class="info-small mb-2 text-dark">{{ userEmail }}</div>
          <router-link to="/logout" class="row-button secondary no-underline inline-block">Logout</router-link>
          <a
            v-if="isDemoUser"
            href="#"
            @click="resetDemo"
            class="row-button secondary no-underline inline-block ml-2"
          >Reset Demo</a>
        </div>
        <div v-if="isDemoUser" class="mt-2"></div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BookRow from "@/components/library/BookRow.vue";

export default {
  components: { BookRow },
  computed: {
    ...mapGetters([
      "libraryLoaded",
      "libraryWithoutAdded",
      "yourLibrary",
      "pausedLibrary",
      "isDemoUser",
      "userEmail"
    ]),
    loaded() {
      return this.libraryLoaded;
    }
  },
  methods: {
    resetDemo() {
      this.$http
        .post("demo/users/resetdemo", {})
        .then(response => {
          this.$router.push({ name: "home" });
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  async mounted() {
    this.$store.dispatch("loadLibrary");
  }
};
</script>

<style lang="scss" scoped>
</style>