<template>
  <div
    class="rounded-xl w-12 h-12 bg-center bg-contain"
    :style="style"
    @click="$emit('click')"
  ></div>
</template>

<script>
export default {
  name: "BookLogo",
  props: {
    logoUrl: { type: String },
  },
  computed: {
    style() {
      return {
        backgroundImage: `url(${this.logoUrl})`,
      };
    },
  },
};
</script>