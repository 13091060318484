const state = { isAuthenticated: undefined, isDemoUser: false, email: "" };

const getters = {
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  userEmail(state) {
    return state.email;
  },
  isDemoUser(state) {
    return state.isDemoUser;
  },
};

const actions = {
  setAuthenticated({ commit }) {
    commit("setIsAuthenticated", true);
  },
  setLocalStorage({ commit }, payload) {
    if (payload.email) commit("setEmail", payload.email);
    if (payload.isdemo) commit("setIsDemoUser", true);
  },
  loadLocalStorageState({ commit, dispatch }) {
    const isDemo = JSON.parse(localStorage.getItem("isDemo"));
    const email = JSON.parse(localStorage.getItem("email"));
    if (isDemo) commit("setIsDemoUser", true);
    if (email) commit("setEmail", email);
  },
  setLoggedOut({ commit, dispatch }) {
    localStorage.clear();
    dispatch("clearUpNext");
    commit("setIsDemoUser", false);
    commit("setIsAuthenticated", false);
  },
};

const mutations = {
  setIsAuthenticated(state, value) {
    state.isAuthenticated = value;
  },
  setEmail(state, value) {
    localStorage.setItem("email", JSON.stringify(value));
    state.email = value;
  },
  setIsDemoUser(state, value) {
    localStorage.setItem("isDemo", JSON.stringify(value));
    state.isDemoUser = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
