<template>
  <div class="select-none">
    <div v-if="error">
      <div class="small-title">
        There was an error loading the next chapter.
        <router-link :to="{ name: 'home' }" class="text-yellow no-underline"
          >Click to retry.</router-link
        >
      </div>
    </div>
    <div v-else>
      <transition name="fade-fast">
        <div v-if="!loaded" class="safe-area loading-indicator-container">
          <div class="loading-indicator"></div>
        </div>
      </transition>

      <!-- Reviews -->
      <div v-if="dueFlashcardsLoaded && dueFlashcards.length" class="mb-5 pb-5">
        <div class="big-copy text-center mt-4">
          You have {{ dueFlashcards.length }}
          <span class="whitespace-no-wrap"
            >{{ dueFlashcards.length > 1 ? "reviews" : "review" }} ready</span
          >
        </div>
        <div class="text-center mt-5">
          <div
            class="button w-48 cursor-pointer"
            @click="$store.dispatch('openReview')"
          >
            Start Review
          </div>
        </div>
      </div>
      <!-- Learning -->
      <div v-else-if="loaded && nextChapter.length === 0">
        <div class="big-copy text-center mb-2">🌱</div>
        <div class="small-title text-center">You're up to date</div>
      </div>
      <div v-else>
        <div class="caps text-yellow text-center">{{ book_title }}</div>
        <div class="big-copy text-center mt-4" :class="{ loading: !loaded }">
          <template v-if="!loaded">Loading...</template>
          <template v-else>
            <div v-html="chapter_title"></div>
          </template>
        </div>
        <!-- <chapter-summary>We take a look at the idea of agents, a central concept in reinforcement learning.</chapter-summary> -->
        <div class="text-center mt-5">
          <div class="button w-32 cursor-pointer" @click="openLearning">
            Start
          </div>
          <!-- <div
            class="button w-32 cursor-pointer"
            @click="openLearningAtChapter(42, 98)"
          >
            Times
          </div> -->
        </div>
      </div>
      <!-- <div @click="$store.dispatch('preloadContent')" class="mt-5 text-center text-light">☉ Refresh</div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChapterSummary from "@/components/home/ChapterSummary.vue";
const R = require("ramda");

export default {
  data() {
    return { next: null, error: false };
  },
  components: { ChapterSummary },
  computed: {
    ...mapGetters([
      "nextChapter",
      "nextChapterLoaded",
      "dueFlashcards",
      "dueFlashcardsLoaded"
    ]),
    book_title() {
      if (this.nextChapter && this.nextChapter.book_title) {
        return this.nextChapter.book_title;
      }
      return "Next";
    },
    chapter_title() {
      // A function to add a nbsp between the last two words
      // so we don't get hanging words.
      // Might be worth putting this into a helper function.
      const title = R.trim(this.nextChapter.chapter_title);
      const titleArr = R.split(" ", title);
      const length = R.length(titleArr);
      if (length === 1) return title;
      const head = R.concat(R.join(" ", R.dropLast(2, titleArr)), " ");
      const lastTwo = R.takeLast(2, titleArr);
      const tail = R.join("&nbsp;", lastTwo);
      const finalStr = R.concat(head, tail);
      return finalStr;
    },
    loaded() {
      return this.nextChapterLoaded && this.dueFlashcardsLoaded;
    }
  },
  methods: {
    openLearning() {
      if (this.nextChapter) this.$store.dispatch("openLearning");
    },
    openLearningAtChapter(b_id, c_id) {
      this.$store.dispatch("openLearningAtChapter", { b_id, c_id });
    }
  },
  async mounted() {
    this.$store.dispatch("preloadContent");
  }
};
</script>

<style lang="scss" scoped>
.loading {
  opacity: 0.5;
}

.loading-indicator-container {
  position: absolute;
  top: 28px;
  right: 24px;
}

.loading-indicator {
  $size: 12px;
  @apply bg-yellow;
  width: $size;
  height: $size;
  border-radius: $size * 2;
}
</style>