<template>
  <div class="app-nav border-b flex select-none">
    <menu-item
      glyph="home"
      label="Today"
      :to="{ name: 'home' }"
      :selected="isSelected()"
    />
    <menu-item
      glyph="library"
      label="Library"
      :to="{ name: 'library' }"
      :selected="isSelected('library')"
    />
  </div>
</template>

<script>
const R = require("ramda");
import { Plugins } from "@capacitor/core";
const { PrimerPlugin } = Plugins;

import MenuItem from "./MenuItem.vue";

// Helpers
const lastComponent = R.pipe(R.split("/"), R.reject(R.isEmpty), R.last);

export default {
  components: { MenuItem },
  methods: {
    isSelected(item) {
      return item === lastComponent(this.$route.path);
    },
    openNativeLogin() {
      PrimerPlugin.openLogin();
    }
  }
};
</script>

<style lang="scss" scoped>
// .app-nav {
//   @apply bg-snow;
// }
</style>