<template>
  <router-link :to="to" tag="div" class="cursor-pointer">
    <div class="p-4 flex items-center text-dark small-title" :class="{ 'opacity-50': !selected }">
      <img :src="glyphImage" />
      <span class="pl-2">{{ label }}</span>
    </div>
  </router-link>
</template>

<script>
const glyphs = {
  home: require("@/assets/images/glyphs/home.svg"),
  library: require("@/assets/images/glyphs/library.svg")
};

export default {
  props: {
    glyph: String,
    label: String,
    to: Object,
    selected: Boolean
  },
  computed: {
    glyphImage() {
      return glyphs[this.glyph];
    }
  }
};
</script>

<style lang="scss" scoped>
</style>