<template>
  <div class="px-4 py-5">
    <div class="caps text-yellow">Recent</div>
    <div v-if="!recentChapters.lastLoaded" class="info mt-4 text-light">Loading...</div>
    <div v-else>
      <div v-for="c in recentChapters.data" :key="c.id" class="mt-4">
        <div class="info-small text-light">{{ c.book_title }}</div>
        <div class="info mt-1">{{ c.chapter_title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["recentChapters"]),
  },
  mounted() {
    this.$store.dispatch("loadRecentChapters");
  },
};
</script>

<style lang="scss" scoped>
</style>