const R = require("ramda");
import api from "@/store/api";
import { addEndFlourish, splitBuilds } from "@/helpers/note-helpers.js";

const state = {
  chapterToLearn: null,
  recentChapters: {
    lastLoaded: null,
    data: [1, 2, 3],
  },
  specificChapter: null,
  currentNoteIndex: 0,
  nextChapterLoaded: false,
  waitingToComplete: false,
};

const getters = {
  nextChapter(state) {
    return state.chapterToLearn;
  },
  nextChapterLoaded(state) {
    return state.nextChapterLoaded;
  },
  recentChapters(state) {
    return state.recentChapters;
  },
  currentNoteIndex(state) {
    return state.currentNoteIndex;
  },
  nextChapterNotes(state, getters) {
    if (getters.nextChapter && getters.nextChapter.notes)
      return splitBuilds(getters.nextChapter.notes);
    else return [];
  },
  currentNote(state, getters) {
    return getters.nextChapterNotes[getters.currentNoteIndex];
  },
  currentNoteNumber(state, getters) {
    return R.inc(getters.currentNoteIndex);
  },
  totalNumberNotes(state, getters) {
    return getters.nextChapterNotes.length;
  },
  noteIndexClamp(state, getters) {
    return R.clamp(0, R.dec(getters.totalNumberNotes));
  },
  firstNote(state, getters) {
    return getters.currentNoteIndex === 0;
  },
  lastNote(state, getters) {
    return getters.currentNoteNumber === getters.totalNumberNotes;
  },
  waitingToComplete(state, getters) {
    return state.waitingToComplete;
  },
};

const mutations = {
  setChapterToLearn(state, upcoming) {
    state.chapterToLearn = upcoming;
  },
  setSpecificChapter(state, { chapter }) {
    state.specificChapter = chapter;
  },
  setCurrentNoteIndex(state, index) {
    state.currentNoteIndex = index;
  },
  setNextChapterLoaded(state, value) {
    state.nextChapterLoaded = value;
  },
  setWaitingToComplete(state, value) {
    state.waitingToComplete = value;
  },
  setRecentChapters(state, value) {
    state.recentChapters.data = value;
    state.recentChapters.lastLoaded = Date.now();
  },
};

const actions = {
  async loadNextChapter({ commit, dispatch, getters }) {
    commit("setNextChapterLoaded", false);
    const chapter = await api.get("user/chapters/next");
    commit("setChapterToLearn", chapter.data);
    if (getters.nextChapter) dispatch("loadFlashcardStatuses");
    commit("setNextChapterLoaded", true);
  },
  async loadSpecificChapter({ commit, dispatch }, { b_id, c_id }) {
    const chapter = await api.get(`user/books/${b_id}/chapters/${c_id}`);
    commit("setChapterToLearn", chapter.data);
    if (getters.nextChapter) dispatch("loadFlashcardStatuses");
    commit("setNextChapterLoaded", true);
  },
  async loadRecentChapters({ commit, dispatch, getters }) {
    try {
      const res = await api.get(`user/chapters/completed`);
      commit("setRecentChapters", res.data);
    } catch (err) {
      console.log("Could not load recently completed chapters.");
    }
  },
  prevNote({ commit, getters }) {
    const index = getters.noteIndexClamp(R.dec(getters.currentNoteIndex));
    commit("setCurrentNoteIndex", index);
  },
  nextNote({ commit, getters }) {
    const index = getters.noteIndexClamp(R.inc(getters.currentNoteIndex));
    commit("setCurrentNoteIndex", index);
  },
  completeChapter({ commit, getters, dispatch }) {
    const chapter_id = getters.nextChapter.chapter_id;
    const book_id = getters.nextChapter.book_id;
    commit("setWaitingToComplete", true);
    api
      .put(`user/chapters/${chapter_id}/complete`, { book_id })
      .then(() => {
        dispatch("clearUpNext");
        dispatch("preloadContent");
        dispatch("closeOverlay");
        commit("setWaitingToComplete", false);
      })
      .catch((err) => {
        console.log("Could not mark chapter as completed.");
        commit("setWaitingToComplete", false);
      });
  },
  clearUpNext({ commit, getters, dispatch }) {
    commit("setChapterToLearn", []);
    commit("setDueFlashcards", []);
    commit("setFlashcardStatuses", []);
  },
};

export default { state, getters, mutations, actions };
