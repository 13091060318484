<template>
  <div class="learning-panel safe-area">
    <div class="header no-border flex opacity-25" @click="backgroundClick">
      <close-button
        class="close-button p-2 -ml-2"
        :style="closeOpacity"
        @click="close"
      />
    </div>
    <div class="note overflow-scroll">
      <div class="note-content medium-copy" v-html="noteHTML"></div>
      <invisible-nav @prev="prevNote" @next="nextNote" />
      <template v-if="hasFlashcards">
        <div
          @click="toggleFlashcards"
          class="button button-secondary review-button"
          :class="{ pulse }"
          v-if="hasUncollectedFlashcards"
        >
          Review questions
        </div>

        <div v-else @click="toggleFlashcards" class="review-button">
          ✓ All questions collected
        </div>
      </template>
      <transition name="slide-left">
        <flashcards-group
          class="flashcards"
          v-if="showFlashcards && currentFlashcard"
        />
      </transition>
    </div>

    <transition name="slide-up">
      <div class="action-panel safe-area" v-if="lastNote">
        <primer-button
          :wide="true"
          :disabled="completeDisabled"
          @click="completeChapter"
          >Complete chapter</primer-button
        >
      </div>
    </transition>
  </div>
</template>

<script>
const { differenceWith } = require("ramda");
import { mapGetters } from "vuex";
import InvisibleNav from "@/components/InvisibleNav.vue";
import PrimerButton from "@/components/PrimerButton.vue";
import FlashcardsGroup from "@/components/flashcards/FlashcardsGroup.vue";
import CloseButton from "@/components/CloseButton.vue";

// Capacitor
import { Plugins, StatusBarStyle } from "@capacitor/core";
const { StatusBar } = Plugins;

export default {
  components: {
    InvisibleNav,
    PrimerButton,
    FlashcardsGroup,
    CloseButton,
  },
  data() {
    return {
      showFlashcards: false,
      pulse: false,
      waitingToActivateComplete: true,
    };
  },
  computed: {
    ...mapGetters([
      "nextChapter",
      "currentFlashcard",
      "flashcardStatuses",
      "currentNote",
      "currentNoteNumber",
      "totalNumberNotes",
      "firstNote",
      "lastNote",
      "waitingToComplete",
    ]),
    completeDisabled() {
      return this.waitingToComplete || this.waitingToActivateComplete;
    },
    closeOpacity() {
      const opacity = this.currentNoteNumber === 1 ? 1 : 0.5;
      return { opacity };
    },
    book_title() {
      return this.$store.getters.bookTitleFromFlashcard(this.nextChapter);
    },
    chapter_title() {
      return this.$store.getters.chapterTitleFromFlashcard(this.nextChapter);
    },
    noteHTML() {
      if (!this.currentNote) return;
      const body = this.currentNote.body || "[blank]";
      return this.$md.makeHtml(body);
    },
    hasFlashcards() {
      if (this.currentNote.isIncremental) return 0;
      else if (this.currentNote) return this.currentNote.flashcards.length;
    },
    uncollectedFlashcards() {
      if (this.currentNote.isIncremental) return [];
      const comparator = (x, y) => x.id === y.flashcard_id;
      const notCollected = differenceWith(
        comparator,
        this.currentNote.flashcards,
        this.flashcardStatuses
      );
      return notCollected;
    },
    hasUncollectedFlashcards() {
      if (this.currentNote.isIncremental) return 0;
      return Boolean(this.uncollectedFlashcards.length);
    },
  },
  methods: {
    prevNote() {
      if (this.showFlashcards) this.toggleFlashcards();
      else this.$store.dispatch("prevNote");
    },
    nextNote() {
      if (this.showFlashcards) this.toggleFlashcards();
      else if (this.hasUncollectedFlashcards) {
        this.pulse = true;
        setTimeout(() => {
          this.pulse = false;
        }, 500);
      } else this.$store.dispatch("nextNote");
    },
    close() {
      if (this.showFlashcards) this.backgroundClick();
      else this.$store.dispatch("closeOverlay");
    },
    completeChapter() {
      if (this.completeDisabled) return;
      this.$store.dispatch("completeChapter");
    },
    toggleFlashcards() {
      this.showFlashcards = !this.showFlashcards;
    },
    backgroundClick() {
      if (this.showFlashcards) this.showFlashcards = false;
    },
  },
  watch: {
    currentFlashcard(to, from) {
      if (to === undefined) this.showFlashcards = false;
    },
    currentNote(to, from) {
      this.showFlashcards = false;
    },
    lastNote(to) {
      if (to === true) {
        this.waitingToActivateComplete = true;
        setTimeout(() => {
          this.waitingToActivateComplete = false;
        }, 1500);
      }
    },
  },
  mounted() {
    if (this.$isNative) StatusBar.hide();
  },
  beforeDestroy() {
    if (this.$isNative) StatusBar.show();
  },
};
</script>

<style lang="scss" scoped>
.learning-panel {
  background: white;
  display: flex;
  flex-direction: column;
}

.header {
  @apply border-b;
  @apply px-4;
  @apply py-3;
  &.no-border {
    border: 0;
  }
}

.note {
  position: relative;
  @apply p-4;
  flex-grow: 1;
}

.action-panel {
  // REFACTOR
  // move to component
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  @apply p-4;
  margin-bottom: 12px;
}

.review-button {
  position: absolute;
}

.flashcards {
  position: absolute;
  left: 24px;
  right: 24px;
  top: 24px;
  bottom: 0;
  margin-bottom: 24px;
  border-radius: 8px;
  @apply shadow-lg;
  @apply bg-lightest;
}
</style>

<style lang="scss">
// Not scoped due to dynamic HTML not getting the scoped attribute
.learning-panel,
.review-panel {
  .note-content p img,
  .card .copy .side p img {
    margin-top: 1rem;
    border: 1px solid #ccc;
  }

  .note-content p {
    opacity: 0.5;
  }
  .note-content p:last-of-type {
    opacity: 1;
  }
}

.close-button {
  transition: opacity 2s ease-in-out;
}
</style>